import styled from "@emotion/styled";

export const SignInWapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SignInContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 48rem;
  margin-top: 11rem;
  padding: 4.2rem 0;
  overflow: auto;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
`;
export const LogoContent = styled.div`
  display: flex;
  justify-content: center;
  max-width: 40%;
  margin-bottom: 1rem;
  margin-top: -2rem;
  img {
    width: 100%;
  }
`;
export const Header = styled.header`
  display: flex;
  justify-content: center;
  margin-top: 5.3rem;
  .brand {
    width: 17.7rem;
    height: 6.7rem;
  }
`;

export const SignInContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2.5rem;
  justify-content: center;
  .title {
    font-size: 3.8rem;
    line-height: 5.04rem;
    font-weight: 700;
  }
  .subtitle {
    font-size: 1.8rem;
    width: 31.3rem;
    text-align: center;
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 150%;
    color: #333333;
  }
  .signin-component {
    display: flex;
    flex-direction: column;
  }
  .slack-button,
  .google-button {
    width: 21.72rem;
    height: 5rem;
    cursor: pointer;
    display: inline-block;
  }
  .google-button {
    height: 100%;
    object-fit: contain;
  }
`;

export const SignInMechanisms = styled.div`
  width: 37.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;
  a {
    text-decoration: none;
    color: #3d3e43;
  }
  .sign-in-email-btn {
    width: 100%;
    border-radius: 1.2rem;
    padding: 1.6rem 4.6rem;

    display: flex;
    justify-content: center;
    background: ${({theme}: any) => theme.colors.button.background};
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #ffffff;
  }
  form {
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
  }
`;

export const SignInWithAuthButton = styled.button`
  width: 100%;
  height: 4.7rem;
  /* margin-right: 3rem; */
  border: 1px solid #e7e5e4;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem 1.6rem;
  border-radius: 7px;
  background-color: transparent;
  cursor: pointer;
  color: #6b7280;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;

  .icon {
    margin-right: 1.4rem;
  }
`;

export const Divider = styled.div`
  text-align: center;
  font-size: 1.5rem;
  color: #4f4d4d;
`;

export const Input = styled.input`
  display: block;
  width: 100%;
  height: 4.9rem;
  padding: 1.1rem 1.5rem;
  background: #ffffff;
  border: 1px solid #e7e5e4;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #6b7280;
`;

export const Terms = styled.div`
  height: 4.5rem;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2.2rem;
  text-align: center;
  color: #71717a;
  align-items: center;
  justify-content: center;
  a {
    color: #1d4ed8 !important;
  }
`;

export const Bold = styled.strong`
  margin: 0 0.25rem;
`;
