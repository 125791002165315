import styled from "@emotion/styled";
import { DialogContent as ReachDialogContent } from "@reach/dialog";
import { Button } from "../../../styled";

export const FileSelectUploadWrapper = styled.div``;

export const DialogContent = styled(ReachDialogContent)`
  width: 74rem;
  border-radius: 2rem;
  box-shadow: 0rem 1rem 5rem hsla(0, 0%, 0%, 0.33);
  padding: 0;
`;

export const SelectOrUploadContent = styled.div`
  height: 48rem;
  display: grid;
  grid-template-columns: 20rem;
  grid-template-areas: "upload choose";
  font-family: var(--font-primary);
`;

export const FileUploadArea = styled.div`
  grid-area: upload;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  row-gap: 1rem;
  text-align: center;
  border-right: 0.05rem solid #e2e8f0;
  .upload_btn {
    padding: 0.8rem 1.2rem;
    background-color: #2563eb;
    border-radius: 0.6rem;
    color: white;
    cursor: pointer;
  }
  .extra-text {
    font-size: 0.8rem;
    line-height: 1.5rem;
    color: #64748b;
  }
`;

export const ChooseFileArea = styled.div`
  grid-area: choose;
  padding: 1.6rem 0 1.2rem 1.6rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  row-gap: 1.2rem;
  .title {
    font-size: 1.2rem;
  }
  > div {
    margin-right: 1.6rem;
  }
`;

export const FilesContainerWrapper = styled.div`
  height: 100%;
  overflow: auto;
  margin-right: 0.6rem !important;
`;

export const FilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  .thumb-card {
    cursor: pointer;
  }
  .selected {
    border: 0.05rem solid #2563eb;
    box-shadow: 0 0 0 0.2rem rgba(37, 99, 235, 0.12);
  }
`;

export const FileSelectModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  background: linear-gradient(
    360deg,
    #ffffff 0%,
    #ffffff 75.52%,
    rgba(255, 255, 255, 0) 97.69%
  );
`;

export const CloseModalButton = styled(Button)`
  background-color: #2563eb;
  padding: 0.6rem 1.6rem;
  font-family: var(--font-primary);
  border-radius: 0.6rem;
  color: #f8fafc;
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
`;
