import FilterTags from "../FilterTags";
import FilterDropdownListContainer from "./FilterDropdownListContainer";
import styled from "@emotion/styled";
import { useContext } from "react";
import { ContentAreaContext } from "../../../../../context";

const FilterContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`;

export const FilterTagHeading = styled.div`
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #0f172a;
`;

const FilterContainer = () => {
  const { showFilter } = useContext(ContentAreaContext);
  return showFilter ? (
    <FilterContainerWrapper>
      <FilterDropdownListContainer />
      <FilterTags
        Header={() => <FilterTagHeading>Filters:</FilterTagHeading>}
      />
    </FilterContainerWrapper>
  ) : (
    <FilterTags />
  );
};

export default FilterContainer;
