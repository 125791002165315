import { ChangeEvent, Fragment, useContext, useEffect, useState } from "react";
import {
  Form,
  Formik,
  FormikErrors,
  FormikTouched,
  useField,
  useFormikContext,
} from "formik";
import { object as YupObject } from "yup";
import papa from "papaparse";
import validator from "validator";
import { FaFileCsv } from "react-icons/fa";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import { useParams } from "react-router-dom";
import { PiUploadSimpleBold } from "react-icons/pi";
import { noop } from "lodash";
import {
  AppIconContainer,
  AppsContainer,
  BrowseFileButton,
  CRMAppContainer,
  CSVFileUploadContainer,
  CSVIconContainer,
  CampaignFormBody,
  CampaignFormContainer,
  CampaignFormFooter,
  CancelFormButton,
  SaveFormButton,
} from "./styled";
import useAddEditCampaignFormData from "../../../../../../api/hooks/campaign/useAddEditCampaignFormData";
import { FormFieldType } from "../../../../../../constants/form";
import { fieldMapper } from "../../../../../../config/formConfig";
import { Header } from "../../../../Reusable/components";
import { AppContext } from "../../../../../../context";
import salesforceIcon from "../../../../../../assets/icons/salesforce.svg";
import hubspotIcon from "../../../../../../assets/icons/hubspot.svg";
import useCreateUpdateCampaign from "../../../../../../api/hooks/useCreateUpdateCampaign";
import { history } from "../../../../../../utils/history";
import {
  CAMPAIGN_CSV_UPLOAD_MESSAGE,
  DEFAULT_CAMPAIGN_EMAIL_LIMIT,
} from "../../../../../../constants/campaign";
import { Separator } from "../../../../../../ui/Separator";
import { cn } from "../../../../../../ui/lib/utils";
import { trackUserAction } from "../../../../../../analytics";
import { userActivityEvents } from "../../../../../../config";

const CAMPAIGN_EMAIL_LIMIT =
  Number(process.env.REACT_APP_CAMPAIGN_EMAIL_LIMIT) ||
  DEFAULT_CAMPAIGN_EMAIL_LIMIT;

interface IAddEditCampaignFormProps {
  campaignId?: string;
  showHeader?: boolean;
  buttonText?: string;
  onSubmit?: () => void;
  onCancel?: () => void;
}

export const getCSVParsedData = async (file: any) => {
  return await new Promise((resolve, reject) => {
    try {
      papa.parse(file, {
        dynamicTyping: true,
        skipEmptyLines: true,
        header: true,
        complete: async function (results) {
          const csvDataWithEmail = results?.data
            ?.filter((data: any) => Boolean(data.email || data.Email))
            ?.splice(
              0,
              CAMPAIGN_EMAIL_LIMIT
                ? CAMPAIGN_EMAIL_LIMIT
                : results?.data?.length
            );

          resolve({ data: csvDataWithEmail, header: results.meta.fields });
        },
      });
    } catch (e) {
      reject(e);
    }
  });
};

const AddEditCampaignForm = ({
  campaignId,
  showHeader = true,
  onSubmit,
  onCancel,
  buttonText,
}: IAddEditCampaignFormProps) => {
  const params = useParams();
  const [initialFormData, setInitialFormData] = useState<any>({
    emails: { tags: [], inputValue: "" },
    csv: undefined,
    name: "",
  });
  const { appDispatch } = useContext(AppContext);
  const { mutate, isLoading } = useCreateUpdateCampaign();

  const formData = useAddEditCampaignFormData(
    params.id || campaignId || "",
    initialFormData
  );
  const isEdit = formData.isEdit;

  useEffect(() => {
    appDispatch({
      type: "SET_PAGE_TITLE",
      payload: formData.isEdit ? formData.editTitle : formData.title,
    });
  }, [appDispatch, formData.editTitle, formData.isEdit, formData.title]);

  const handleCsvFileUpload = async (
    e: ChangeEvent<HTMLInputElement>,
    emails: { tags: any[]; inputValue: string },
    name: string
  ) => {
    if (!e.target?.files?.length) return false;
    const file = e.target.files[0];
    const data: any = await getCSVParsedData(file);
    const initialFormData: { emails: any; csv: any; name: string } = {
      emails: { tags: [...emails.tags], inputValue: emails.inputValue },
      csv: undefined,
      name,
    };

    const csvEmailData = data?.data.reduce(
      (emailIds: any[], csvRowData: any) => {
        emailIds.push(csvRowData);
        return emailIds;
      },
      []
    );
    initialFormData.emails = {
      ...initialFormData.emails,
      tags: initialFormData.emails.tags.concat(csvEmailData),
    };
    initialFormData.csv = file;
    setInitialFormData(initialFormData);
    e.target.value = "";
  };

  const handleFormSubmit = (values: any, { setSubmitting }: any) => {
    const mutationData = formData.formFields.reduce(
      (mutationObj: any, fieldObj) => {
        if (fieldObj.modelField) {
          mutationObj[fieldObj.modelField] = values[fieldObj.name];
        }
        return mutationObj;
      },
      {}
    );
    if (isEdit) {
      mutationData.id = campaignId || params.id;
    }

    const mutationOption = {
      onSuccess: (data: any, variables: any, context: any) => {
        setSubmitting(false);
        toast.success(
          `Successfully ${formData.isEdit ? "updated" : `created new campaign`}`
        );
        onSubmit ? onSubmit() : history.push(`/user/campaigns/${data.data.id}`);
        if (!formData.isEdit)
          trackUserAction(userActivityEvents.CAMPAIGN__CREATED_VIA_BUTTON, {
            campaignId: data.data.id,
            name: data.data.name,
            users_count: data.data.users_count,
          });
      },
      onError: () => {
        setSubmitting(false);
      },
    };
    delete mutationData?.["csv"];
    if (
      mutationData?.hasOwnProperty("users_data") &&
      mutationData?.["users_data"]
    ) {
      const usersData = mutationData?.users_data?.inputValue
        .split(",")
        .reduce((userData: any, email: string) => {
          email && userData.push({ email });
          return userData;
        }, []);
      mutationData.users_data = [
        ...mutationData?.users_data?.tags,
        ...usersData,
      ];
      mutate(mutationData, mutationOption);
    }
  };

  const scrollToTouchedErrorField = (
    errors: FormikErrors<any>,
    touched: FormikTouched<any>
  ) => {
    const touchedErrorField = Object.entries(errors).find(
      ([fieldName, error]) => error
    );
    if (touchedErrorField) {
      const fieldElement =
        touchedErrorField[0] && document.getElementById(touchedErrorField[0]);
      if (fieldElement)
        fieldElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
    }
  };

  const getFieldToRender = (fieldName: string, fieldObj: any) => {
    let { label, type, required } = fieldObj;
    let showClearAllBtn = false;
    delete fieldObj.label;
    if ((fieldObj?.name).includes("email")) {
      required = true;
      showClearAllBtn = true;
    }

    const handleClearAll = () => {
      setInitialFormData((intialFormData: any) => ({
        ...intialFormData,
        emails: { tags: [], inputValue: "" },
        csv: undefined,
      }));
    };

    const getFormField = () => (
      <div className="flex flex-col gap-5 font-primary">
        <div className="flex items-center justify-between">
          <div
            className={cn(
              "text-xl font-semibold text-black",
              required && `after:content-['_*']`
            )}
          >
            {label}
          </div>
          {showClearAllBtn &&
            Boolean(initialFormData?.emails?.tags?.length) && (
              <div
                className="text-base font-medium px-6 text-red-500 cursor-pointer"
                onClick={handleClearAll}
              >
                Clear all
              </div>
            )}
        </div>
        {fieldMapper[type]?.(fieldObj)}
      </div>
    );

    switch (fieldName) {
      case "csv": {
        return (
          <div>
            <CsvUploadField
              label={label}
              fieldObj={fieldObj}
              handleCsvFileUpload={handleCsvFileUpload}
            />
          </div>
        );
      }
      default: {
        return getFormField();
      }
    }
  };

  const getFields = () => {
    if (!formData.formFields) return <></>;

    return formData.formFields.map((formFieldObj, i) => {
      const propsObj: any = {
        key: formFieldObj.type + formFieldObj.name,
        id: formFieldObj.name,
        label: formFieldObj.label,
        type: formFieldObj.type,
        className: "",
        focusedClassName: "!border-purple-600 !shadow-formPurple",
        name: formFieldObj.name,
        required: Boolean(formFieldObj?.validation?.exclusiveTests?.required),
        autoFocus: i === 0,
        ...formFieldObj.fieldData,
      };
      delete propsObj.initialValue;
      return getFieldToRender(formFieldObj.name, propsObj);
    });
  };

  return (
    <CampaignFormContainer className="CampaignForm__container">
      {showHeader && <Header showSearchBar={false}></Header>}
      <Formik
        initialValues={{ ...formData.initialValues }}
        enableReinitialize={true}
        validationSchema={YupObject(formData.validationSchema)}
        onSubmit={handleFormSubmit}
      >
        {({ errors, touched, isSubmitting, handleSubmit }) => {
          return (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
                setTimeout(() => {
                  scrollToTouchedErrorField(errors, touched);
                }, 1);
              }}
            >
              <CampaignFormBody className="CampaignForm__body">
                {getFields()}
                <Separator />
                <CampaignFormFooter>
                  <CancelFormButton
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      onCancel ? onCancel() : history.back();
                    }}
                    className="!mt-0 !h-max !px-16 !py-4"
                  >
                    <span>Cancel</span>
                  </CancelFormButton>
                  <SaveFormButton
                    type={"submit"}
                    disabled={isSubmitting}
                    className={cn(
                      "!mt-0 !px-5 !py-4 !rounded-2xl",
                      !isSubmitting &&
                        "!bg-formSaveBtn !shadow-formSaveBtn !border !border-solid !border-purple-100"
                    )}
                  >
                    <span>
                      {buttonText ??
                        (formData.isEdit
                          ? "Add to campaign"
                          : "Create campaign")}
                    </span>
                    {isSubmitting ? (
                      <ClipLoader color="white" size={16}></ClipLoader>
                    ) : (
                      <></>
                    )}
                  </SaveFormButton>
                </CampaignFormFooter>
              </CampaignFormBody>
            </Form>
          );
        }}
      </Formik>
    </CampaignFormContainer>
  );
};

export default AddEditCampaignForm;

interface IAppsForDataSource {
  handleCsvFileUpload: (
    e: ChangeEvent<HTMLInputElement>,
    emailIds: string
  ) => void;
}

const AppsForDataSource = ({ handleCsvFileUpload }: IAppsForDataSource) => {
  const formik = useFormikContext();
  const appsData = [
    {
      name: "salesforce",
      icon: salesforceIcon,
      style: { background: "rgb(240, 249, 255)" },
    },
    {
      name: "hubspot",
      icon: hubspotIcon,
      style: { background: "#FEF2F2" },
    },
  ];
  return (
    <AppsContainer>
      <CSVFileUploadContainer>
        <CSVIconContainer>
          <FaFileCsv size={"2.8rem"} className="csv-icon" color="#1e293b" />
        </CSVIconContainer>
        <label htmlFor="csv-upload">
          <BrowseFileButton>Upload csv</BrowseFileButton>
          <input
            id="csv-upload"
            type="file"
            accept=".csv"
            value={""}
            onChange={(e) => {
              handleCsvFileUpload(e, (formik?.values as any)?.emails);
            }}
          />
        </label>
      </CSVFileUploadContainer>
      {appsData.map((appData, index) => (
        <CRMAppContainer key={appData.name + index}>
          <AppIconContainer style={appData.style}>
            <img src={appData.icon} alt={appData.name}></img>
          </AppIconContainer>
        </CRMAppContainer>
      ))}
    </AppsContainer>
  );
};

interface ICsvUploadField {
  label: string;
  fieldObj: any;
  handleCsvFileUpload: (
    e: ChangeEvent<HTMLInputElement>,
    emails: { tags: any[]; inputValue: string },
    name: string
  ) => void;
}

const CsvUploadField = ({
  label,
  fieldObj,
  handleCsvFileUpload = noop,
}: ICsvUploadField) => {
  const { type, ...propsObj } = fieldObj;
  const [field, meta, helpers] = useField(propsObj);
  const formik = useFormikContext();

  return (
    <div className="flex flex-col items-center justify-center gap-4 px-6 py-8 bg-purple-50 rounded-xl relative overflow-hidden">
      <div className="absolute top-[-50%] left-[8%] bg-[#f2ebf9] w-[83%] h-[200%] rounded-full z-[1]"></div>
      <div className="absolute top-[-50%] left-[19%] bg-[#f4edfb] w-[60%] h-[200%] rounded-full z-[2]"></div>
      <div className="absolute top-[-26%] left-[30%] bg-[#f6f0fd] w-[40%] h-[150%] rounded-full z-[3]"></div>
      <div className="text-xl font-semibold text-gray-900 z-10">{label}</div>
      <label htmlFor="csv-upload" className="z-10">
        <div className="flex gap-2 items-center text-purple-500 text-lg font-medium px-4 py-2 rounded-2xl h-auto border border-solid w-max cursor-pointer border-purple-300 bg-transparent z-10">
          <PiUploadSimpleBold size={15} />
          {fieldObj.browseFileButtonLabel}
        </div>
        <input
          id="csv-upload"
          type="file"
          accept=".csv"
          {...{ ...field, value: undefined }}
          onChange={(e) => {
            if (e.target.files?.length) {
              helpers.setValue(e.target.files[0]);
              handleCsvFileUpload(
                e,
                (formik?.values as any)?.emails,
                (formik?.values as any)?.name
              );
            }
          }}
          className="hidden"
        />
      </label>
      <div className="font-primary text-lg text-gray-700 text-center font-medium z-10">
        {CAMPAIGN_CSV_UPLOAD_MESSAGE}
      </div>
    </div>
  );
};
