import styled from "@emotion/styled";

export const SearchSelectCardWrapper = styled.div`
  position: relative;
  min-height: 27rem;
  width: 21.3rem;
  background: var(--white);
  border: 0.1rem solid #cbd5e1;
  border-radius: 0.8rem;
  font-family: var(--font-primary);
  overflow: hidden;
`;

export const SearchSelectHeadSearchWrapper = styled.div`
  padding: 1.2rem;
  padding-bottom: 0.6rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
`;

export const SearchSelectHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  .SearchSelect__title {
    font-size: 1.3rem;
    line-height: 2rem;
  }
  .SearchSelect__reset {
    color: #ef4444;
    font-size: 1rem;
    line-height: 1.5rem;
    cursor: pointer;
  }
`;

export const SearchSelectSearchBar = styled.div`
  display: flex;
  align-items: center;
  padding: 0.4rem 0.8rem;
  column-gap: 0.7rem;
  border: 0.05rem solid #cbd5e1;
  min-height: 2.7rem;
  border-radius: 0.6rem;
  .SearchSelect__search-icon {
    height: 1.2rem;
    width: 1.2rem;
  }
  .SearchSelect__search-input {
    width: 100%;
    font-family: var(--font-primary);
    font-size: 1rem;
    line-height: 1.8rem;
    border: none;
    ::placeholder {
      font-size: 1rem;
      color: #64748b;
    }
    :focus {
      outline: none;
      font-size: 1rem;
    }
  }
`;

export const SearchSelectCheckboxContainer = styled.div`
  max-height: 15.2rem;
  overflow-y: auto;
  :focus{
    outline: none;
  }
`;

export const SearchSelectOptionWrapper = styled.div`
  position: relative;
  padding: 0.8rem;
  padding-left: 3.4rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.8rem;

  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .SearchSelect__option-label {
    cursor: pointer;
    font-weight: 300;
    font-size: 1rem;
  }
  .SearchSelect__checkmark {
    position: absolute;
    top: 50%;
    left: 1.2rem;
    height: 1.2rem;
    width: 1.2rem;
    padding: 0.25rem 0.2rem;
    margin-top: -0.6rem;
    border: 0.04rem solid #cbd5e1;
    border-radius: 0.3rem;
    color: white;
  }
  :hover {
    background-color: #f8fafc;
    .SearchSelect__checkmark {
      color: #f8fafc;
    }
  }
  input:checked ~ .SearchSelect__checkmark {
    border: none;
    background-color: #2563eb;
  }
`;

export const SearchSelectOptionContentWrapper = styled.div`
  display: flex;
`;

export const CreateNewTagContainer = styled.div`
  position: absolute;
  height: 3.9rem;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CreateNewTagButton = styled.div`
  margin: 0 1.2rem 1.3rem;
  font-size: 1rem;
  line-height: 1.7rem;
  color: #475569;
  font-weight: 400rem;
  padding: 0.5rem 0.8rem;
  background-color: #f1f5f9;
  border-radius: 0.6rem;
  border: 0.05rem solid #cbd5e1;
  width: 100%;
  text-align: center;
  cursor: pointer;
`;

export const CreateNewTagInputContainer = styled.div`
  background-color: var(--white);
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 1rem;
  padding: 0.6rem 1.2rem;
  box-shadow: 0rem -0.4rem 0.4rem rgba(0, 0, 0, 0.03);
`;

export const CreateNewTagTextBox = styled.input`
  width: 100%;
  border: 1px solid #cbd5e1 !important;
  border-radius: 0.6rem;
  height: 2.7rem;
  padding: 1rem;
  font-size: 1rem;
  color: #475569 !important;
`;

export const CreateNewTagSubmit = styled.button`
  height: 2.7rem;
  background-color: white;
  box-shadow: none;
  border: 0.05rem solid #cbd5e1;
  border-radius: 0.6rem;
  color: #2563eb;
  padding-top: 0.5rem;
  cursor: pointer;
`;
