import { useState, useEffect, useContext } from "react";
import searchLogo from "../../../../../assets/icons/searchLogo.svg";
import {
  IconWrapper,
  SearchInputIconWrapper,
  SearchMainContentWrapper,
  SearchText,
  RemoveSearchText,
  SearchBarWrapper,
  SearchDropdownWrapper,
} from "./styled";

import useDebounce from "../../../../../hooks/useDebounce";
import { ISearchItem } from "../../../../../interfaces/hooks";
import useGetAutoCompleteSearch from "../../../../../api/hooks/useGetAutoCompleteSearch";
import SearchDropdownList from "../SearchDropdown";
import { ClipLoader } from "react-spinners";
import { IoMdClose } from "react-icons/io";
import { SolutionsContext } from "../../../../../context";
import { trackUserAction } from "../../../../../analytics";
import { userActivityEvents } from "../../../../../analytics/userActivityEvents";
import { AssetObjectType } from "../../../../../config/assetsConfig";
interface ISearchBar {
  searchbarPlaceholder?: string;
  isSolutionLibrary?: boolean;
  excludeSearchObjects?: string[];
  includeSearchObjects?: string[];
}
function SearchBar({
  searchbarPlaceholder = "Search",
  isSolutionLibrary = false,
  excludeSearchObjects = [],
  includeSearchObjects = [],
}: ISearchBar) {
  const SEARCHBAR_PLACEHOLDER = searchbarPlaceholder;
  const { solutionsState, setSolutionSearchText } =
    useContext(SolutionsContext);

  const [searchText, setSearchText] = useState("");

  const [autoCompleteSearchResults, setAutoCompleteSearchResults] = useState<
    ISearchItem[] | undefined
  >([]);

  const debouncedSearchText = useDebounce(searchText, 600);
  const [isDropdownListOpen, setIsDropdownListOpen] = useState(false);

  const { data: autoCompleteSearchResponse, isLoading: SearchInProgress } =
    useGetAutoCompleteSearch({
      searchText: debouncedSearchText,
      filters: {
        objects: includeSearchObjects,
        exclude_objects: excludeSearchObjects,
        solution: isSolutionLibrary ? solutionsState?.solutionId : "",
      },
    });

  useEffect(() => {
    const checkSearchObjectType = (object: string) => {
      if (includeSearchObjects.length) {
        return includeSearchObjects.some((value) => value.includes(object));
      }
      return excludeSearchObjects.every((value) => !value.includes(object));
    };
    const isLibrary = checkSearchObjectType(AssetObjectType.GENERIC_RESOURCE);
    const isSolution = checkSearchObjectType("solution");
    const isCampaign = checkSearchObjectType("campaign");
    const isAccount = checkSearchObjectType("customer_account");
    let event = "",
      object = "";
    if (isSolutionLibrary) {
      event = userActivityEvents.SEARCH__SOLUTION_LIBRARY_SEARCH_USED;
      object = "solution_library";
    } else if (isLibrary) {
      event = userActivityEvents.SEARCH__LIBRARY_SEARCH_USED;
      object = AssetObjectType.GENERIC_RESOURCE;
    } else if (isSolution) {
      event = userActivityEvents.SEARCH__PITCH_LIST_SEARCH_USED;
      object = "solution";
    } else if (isCampaign) {
      event = userActivityEvents.SEARCH__CAMPAIGN_LIST_SEARCH_USED;
      object = "campaign_list";
    } else if (isAccount) {
      event = userActivityEvents.SEARCH__ACCOUNT_LIST_SEARCH_USED;
      object = "account_list";
    }

    if (debouncedSearchText && !SearchInProgress)
      trackUserAction(
        autoCompleteSearchResults?.length
          ? event
          : userActivityEvents.SEARCH__GOT_NO_RESULTS,
        {
          searchText: debouncedSearchText,
          object,
        }
      );
  }, [autoCompleteSearchResults, debouncedSearchText, SearchInProgress]);

  useEffect(() => {
    setAutoCompleteSearchResults(
      autoCompleteSearchResponse?.hits
        ? autoCompleteSearchResponse?.hits
        : undefined
    );
    setIsDropdownListOpen(true);
  }, [autoCompleteSearchResponse]);
  function handleSearch(
    e: React.KeyboardEvent<HTMLInputElement>,
    searchText: string
  ) {
    if (!searchText) return;
    if (e.key === "Enter") {
      setIsDropdownListOpen(false);
    }
  }

  return (
    <SearchBarWrapper>
      <SearchMainContentWrapper className="SearchBar__main-content">
        <SearchInputIconWrapper>
          <IconWrapper>
            <img src={searchLogo} alt="Search Asset" />
          </IconWrapper>

          <SearchText
            placeholder={SEARCHBAR_PLACEHOLDER}
            onKeyDown={(e) => handleSearch(e, searchText)}
            value={searchText}
            onChange={(e) => {
              const newSearchText = e.target.value;
              setSearchText(newSearchText);
              setSolutionSearchText(newSearchText);
            }}
          />
          <RemoveSearchText
            onClick={() => {
              setSearchText("");
              setSolutionSearchText("");
            }}
            className={`${!!searchText.length ? "show-cross" : ""}`}
          >
            {SearchInProgress ? (
              <ClipLoader color="grey" size={18} />
            ) : (
              <IoMdClose className="close-icon" />
            )}
          </RemoveSearchText>
        </SearchInputIconWrapper>
      </SearchMainContentWrapper>
      <SearchDropdownWrapper>
        <SearchDropdownList
          isSolutionLibrary={isSolutionLibrary}
          searchItems={autoCompleteSearchResults}
          searchText={autoCompleteSearchResponse?.search_text || ""}
          searchValue={searchText}
          isDropdownListOpen={isDropdownListOpen}
          SearchInProgress={SearchInProgress}
          setIsDropdownListOpen={setIsDropdownListOpen}
        />
      </SearchDropdownWrapper>
    </SearchBarWrapper>
  );
}

export default SearchBar;
