import styled from "@emotion/styled";

export const TabWrapper = styled.div`
  color: var(--text-blue-grey);
  font-size: 1.4rem;
  line-height: 2.1rem;
  padding: 1.6rem;
  border-radius: 0.6rem;
  cursor: pointer;
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: #f8f8f8;
  }
  &.active {
    background: var(--selected-tab);
    position: relative;
    color: #2563eb;
    justify-content: space-between;
  }
`;
export const TabLabel = styled.div`
  display: flex;
`;

export const TabEndComponent = styled.div`
  display: flex;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2rem;
  text-align: right;
  color: #2563eb;
`;
