export const apiConfig = {
  urls: {
    invitation: "/teams/invitations/",
    settings: "/settings/",
    assets: "/assets/",
    documents: "/documents/",
    accounts: "/accounts/",
    contacts: "/contacts/",
    solutions: "/solutions/",
    analytics: "/analytics/",
    analyticsLogs: "/analytics/",
    research: "/research/run/",
    shareables: "/shareables/",
    shareableResPublicData: "/shareables/public-data/",
    shareableResToken: "/shareables/token/",
    campaigns: "/campaigns/",
    campaignUser: "/campaignusers/",
    variables: "/variables/",
    prompts: "/prompts/",
  },
};
