import styled from "@emotion/styled";

export const Button = styled.a`
  background: #2563eb;
  font-size: 1.3rem;
  color: #f8fafc;
  font-weight: 400;
  font-family: var(--font-primary);
  padding: 1.2rem 1.6rem;
  border-radius: 0.6rem;
  cursor: pointer;
  ::before {
    content: "+ ";
  }
`;

export const AddAssetsBtn = styled(Button)`
  background: #ffffff;
  border: 1px solid #cbd5e1;
  border-radius: 0.8rem;
  margin-left: 1rem;
  color: #475569;
  ::before {
    content: "";
  }
`;

export const NoContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
`;

export * from "./CreateNewSolution";
