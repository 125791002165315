import styled from "@emotion/styled";
import { FiSearch } from "react-icons/fi";

export const SearchBoxContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0rem 1.2rem;
  gap: 1rem;
  min-height: 3rem;
  background: #ffffff;
  border: 0.05rem solid #cbd5e1;
  border-radius: 0.6rem;
`;

export const SearchIcon = styled(FiSearch)`
  height: 1.2rem;
  width: 1.2rem;
  font-weight: 700;
  color: #64748b;
`;

export const TextInputField = styled.div`
  width: 100%;
  .SearchBox__text-input {
    width: 100%;
    outline: none;
    border: none;
    font-family: var(--font-primary);
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.8rem;
    ::placeholder {
      color: #64748b;
    }
  }
`;
