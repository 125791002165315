import styled from "@emotion/styled";

export const AnalyticsEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.2rem;
  font-family: var(--font-primary);
  img {
    height: 4.2rem;
    margin-bottom: 3.2rem;
  }
`;

export const PopupText = styled.p`
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.6rem;
  color: #64748b;
`;
interface IEmailInput {
  isValidEmail: boolean;
}

export const EmailInput = styled.input<IEmailInput>`
  font-family: var(--font-primary);
  margin-top: 1.2rem;
  width: 100%;
  padding: 1.2rem 1.6rem;
  background: #ffffff;
  border: ${({ isValidEmail }) =>
    `1px solid ${isValidEmail ? "#e7e5e4" : "#B91C1C"}`};
  border-radius: 0.6rem;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #0f172a;
  box-shadow: none;
  &:focus {
    border: 1px solid #2563eb;
    box-shadow: 0 0 0 0.4rem rgba(37, 99, 235, 0.1);
    outline: none;
  }
  &::placeholder {
    color: #a1a1aa;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 2rem;
  }
`;

export const ViewProposalButton = styled.div`
  padding: 1.2rem 2.4rem;
  background: #1d4ed8;
  mix-blend-mode: normal;
  border-radius: 0.6rem;
  margin-top: 0.4rem;
  margin-bottom: 3.2rem;
  font-family: var(--font-primary);
  font-weight: 400;
  font-style: normal;
  font-size: 1.3rem;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
`;

export const ValidationErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  column-gap: 1rem;
  margin-top: 1.2rem;
`;

export const ErrorText = styled.div`
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #b91c1c;
`;
