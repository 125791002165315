export const userActivityEvents = {
  SIGNIN__BUTTON_CLICKED: "SignIn: Button clicked",
  SIGNIN__FLOW_COMPLETED: "SignIn: Flow completed",
  SIGNIN__ERROR: "SignIn: Error",
  SIGNIN__JOIN_WORKSPACE_CLICKED: "SignIn: Join Workspace clicked",
  SIGNIN__WORKSPACE_SELECTED: "SignIn: Workspace selected",
  SIGNIN__JOINED_WORKSPACE: "SignIn: Joined workspace",
  ONBOARD__UPDATED_ACCOUNT_INFO: "Onboarding: Updated account info",
  ONBOARD__INVITED_USERS: "Onboarding: Users invited",
  ONBOARD__SKIPPED_INVITE_SCREEN: "Onboarding: Skipped invite screen",
  NAV__HOME_CLICKED: "Navigation: Home clicked",
  NAV__LIBRARY_CLICKED: "Navigation: Library clicked",
  NAV_SETTINGS_CLICKED: "Navigation: Setting clicked",
  LIBRARY__TAB_CHANGED: "Library: Tab changed",
  LIBRARY__FILTERS_APPLIED: "Library: Filters applied",
  LIBRARY__FILTERS_CLEARED: "Library: Filters cleared",
  LIBRARY__LIST_ITEM_CLICKED: "Library: List item clicked",
  LIBRARY__DOCUMENT_BUTTON_CLICKED: "Library: Document button clicked",
  SEARCH__SEARCH_RESULT_ITEM_CLICKED: "Search: Search result item clicked",
  SEARCH__ACCOUNT_LIST_SEARCH_USED: "Search: Account list search used",
  SEARCH__GOT_NO_RESULTS: "Search: Got no results",
  LOGOUT_CLICKED: "Logout clicked",
  HOME__CARD_CLICKED: "Home: Card clicked",
  SETTINGS__CARD_CLICKED: "Settings: Card clicked",
  HOME__FILTERS_CLEARED: "Home: Filters cleared",
  HOME__EXPLORE_CLICKED: "Home: Explore button clicked",
  HOME__SEE_ALL_CLICKED: "Home: See All button clicked",
  LIBRARY__ASSETS_ADD_BUTTON_CLICKED: "Library: Assets add edit button clicked",
  ASSETSADDEDITFORM__CANCEL_BUTTON_CLICKED:
    "AssetsAddEditForm: Cancel button clicked",
  ASSETSADDEDITFORM__SAVE_BUTTON_CLICKED:
    "AssetsAddEditForm: Save button clicked",
  ASSETADDEDITFORM__CREATE_NEW_TAG_CLICKED:
    "AssetsAddEditForm: Create new tag button clicked",
  PITCH_INTERNAL__PAGE_VIEWED: "Pitch internal: Page viewed",
  PITCH_INTERNAL__ANALYTICS_VIEWED: "Pitch internal: Analytics viewed",
  PITCH_INTERNAL__CREATED_VIA_BUTTON: "Pitch internal: Created via button",
  PITCH_INTERNAL__LIST_PAGE_VIEWED_MY_TAB:
    "Pitch internal: List page viewed (My tab)",
  PITCH_INTERNAL__LIST_PAGE_VIEWED_ALL_TAB:
    "Pitch internal: List page viewed (All tab)",
  PITCH_INTERNAL__NEW_SUBSECTION_CREATED:
    "Pitch internal: New subsection created",
  PITCH_INTERNAL__ADDED_FROM_LIBRARY: "Pitch internal: Added from library",
  PITCH_INTERNAL__SUBSECTION_EDITED: "Pitch internal: Subsection edited",
  PITCH_INTERNAL__SUBSECTION_DELETED: "Pitch internal: Subsection deleted",
  CAMPAIGN__LIST_VIEWED: "Campaign: List viewed",
  CAMPAIGN__CREATED_VIA_BUTTON: "Campaign: Created via button",
  CAMPAIGN__DETAILS_VIEWED: "Campaign: Details viewed",
  RESEARCH__PAGE_VIEWED: "Research: Page viewed",
  RESEARCH__EMAIL_TEMPLATE_CHANGED: "Research: Email template changed",
  SEARCH__PITCH_LIST_SEARCH_USED: "Search: Pitch list search used",
  SEARCH__LIBRARY_SEARCH_USED: "Search: Library search used",
  SEARCH__SOLUTION_LIBRARY_SEARCH_USED: "Search: Solution library search used",
  SEARCH__CAMPAIGN_LIST_SEARCH_USED: "Search: Campaign list search used",
  SEARCH__CAMPAIGN_DETAILS_SEARCH_USED: "Search: Campaign details search used",
  SETTINGS__BUYER_SEGMENTATION_VIEWED: "Settings: Buyer segmentation viewed",
  SETTINGS_PITCH_SECTIONS_VIEWED: "Settings: Pitch sections viewed",
  SETTINGS__INTEGRATIONS_VIEWED: "Settings: Integrations viewed",
  SETTINGS__SALESFORCE_CONNECT_CLICKED: "Settings: Salesforce connect clicked",
  SETTINGS__SALESFORCE_DETAILS_PAGE_VIEWED:
    "Settings: Salesforce details page viewed",
  SETTINGS__HUBSPOT_CONNECT_CLICKED: "Settings: Hubspot connect clicked",
  SETTINGS__HUBSPOT_DETAILS_PAGE_VIEWED:
    "Settings: Hubspot details page viewed",
};
