import styled from "@emotion/styled";
import ReactTooltip from "react-tooltip";

interface ISolutionAssets {
  isShared?: boolean;
}

export const SolutionAssetsCardsContainer = styled.div<ISolutionAssets>`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin: ${({ isShared }) =>
    isShared ? "0 0 1rem 0" : "2rem 2rem 5rem 2rem"};
  background: ${({ isShared }) => (isShared ? "none" : "#ffffff")};
  box-shadow: ${({ isShared }) =>
    isShared ? "none" : "0 2rem 25rem rgba(0, 0, 0, 0.06)"};
  border-radius: 1.2rem;
  overflow: hidden;
  gap: 1rem;
`;

export const Card = styled.div<ISolutionAssets>`
  padding: 1rem 3rem;
  padding-right: 1.6rem;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({ isShared }) =>
    isShared ? "0.5px solid #e2e8f0" : "0.5px solid #cbd5e1"};
  border-radius: 1.2rem;
  box-shadow: ${({ isShared }) =>
    isShared
      ? "rgb(0 0 0 / 10%) 0px 0.2rem 0.4rem"
      : "0 1rem 4rem rgba(0, 0, 0, 0.06)"};
  background: ${({ isShared }) => (isShared ? "#ffffff" : "none")};
`;

export const CardContentWrapper = styled.div`
  width: 100%;
  line-height: 2rem;
  font-weight: 300;
`;

export const Title = styled.div`
  color: black;
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 2.4rem;
  display: flex;
  justify-content: space-between;
  padding: 0.6rem 0;
`;
interface IDescription {
  contentStyle?: any
}

export const Description = styled.div<IDescription>`
  &.card-content-description {
    p {
      ${({ contentStyle }) => contentStyle?.normal}
    }
    h2 {
      ${({ contentStyle }) => contentStyle?.title}
    }
    h3 {
      ${({ contentStyle }) => contentStyle?.h2}
    }
    h4 {
      ${({ contentStyle }) => contentStyle?.h3}
    }
  }
  .b-embed {
    max-width: 97%;
  }
  /* color: #64748b; */
  color: black;
  font-size: 1.4rem;
  overflow-y: hidden;
  @media screen and (max-width: 960px) {
    .b-embed {
      max-width: 100%;
    }
    img {
      max-width: 100%;
    }
  }
  .tiny-pageembed--21by9,
  .tiny-pageembed--16by9,
  .tiny-pageembed--4by3,
  .tiny-pageembed--1by1 {
    display: block;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100%;
  }

  .tiny-pageembed--21by9 {
    padding-top: 42.857143%;
  }

  .tiny-pageembed--16by9 {
    padding-top: 56.25%;
  }

  .tiny-pageembed--4by3 {
    padding-top: 75%;
  }

  .tiny-pageembed--1by1 {
    padding-top: 100%;
  }

  .tiny-pageembed--21by9 iframe,
  .tiny-pageembed--16by9 iframe,
  .tiny-pageembed--4by3 iframe,
  .tiny-pageembed--1by1 iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

export const TagAndButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1.6rem;
`;

export const DocumentsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-bottom: 2rem; */
`;

export const CardHeader = styled.div<ISolutionAssets>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ isShared }) =>
    isShared ? "none" : "rgba(30, 58, 138, 0.03)"};
  box-shadow: ${({ isShared }) =>
    isShared ? "none" : "0 1rem 4rem rgba(0, 0, 0, 0.06)"};
  border-radius: 1.2rem;
  border: ${({ isShared }) => (isShared ? "none" : "1px solid #cbd5e1")};
`;

export const HeaderTitle = styled.div<ISolutionAssets>`
  padding: ${({ isShared }) => (isShared ? "2rem 1rem 1rem 2rem" : "2rem")};
  font-weight: 600;
  font-size: 2rem;
  line-height: 3rem;
  display: flex;
  align-items: center;
  color: #1e3a8a;
`;

export const HeaderButtonContainer = styled.div`
  min-width: 26.4rem;
  padding: 1.6rem;
  display: flex;
  gap: 1rem;
`;

export const CreateNewButton = styled.div`
  padding: 0.8rem;
  background: #ffffff;
  border: 1px solid #cbd5e1;
  border-radius: 0.8rem;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #334155;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  cursor: pointer;
`;

export const AddFromLibrary = styled(CreateNewButton)``;

export const WidgetToolbarContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const WidgetTooltip = styled(ReactTooltip)`
  &.type-dark.place-top,
  &.type-dark.place-bottom,
  &.type-dark.place-right,
  &.type-dark.place-left {
    background: #ffffff;
    border-radius: 6px;
    padding: 0.8rem 0.6rem;
    padding-left: 1.2rem;
    font-family: var(--font-primary);
    font-size: 1.2rem;
    line-height: 2rem;
    color: #0f172a;
    opacity: 1;
    max-width: 20rem;
    z-index: 100;
    white-space: pre-wrap;
    border: 0.5px solid #e2e8f0;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.16);
  }
  &.type-dark.place-top {
    &::after,
    &::before {
      border-top-color: #ffffff;
    }
  }
  &.type-dark.place-bottom {
    &::after,
    &::before {
      border-bottom-color: #ffffff;
    }
  }
  &.type-dark.place-left {
    &::after,
    &::before {
      border-left-color: #ffffff;
    }
  }
  &.type-dark.place-right {
    &::after,
    &::before {
      border-right-color: #ffffff;
    }
  }
`;

WidgetTooltip.defaultProps = {
  place: "left",
  effect: "solid",
};
