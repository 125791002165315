import styled from "@emotion/styled";
import { Button } from "../../../../../../../src/components";

export const SolutionAddEditFormContainer = styled.div`
  font-family: var(--font-primary);
  background: ${({ theme }: any) => theme.colors.body};
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4rem;
  .Header__title {
    color: white;
    font-size: 3rem;
  }
`;

export const SolutionAddEditFormBody = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  width: 57.4rem;
  border: 1px solid #cbd5e1;
  border-radius: 1.2rem;
  /* margin-left: 3.2rem; */
  margin-bottom: 8.5rem;
  margin-top: 2rem;
  padding: 2rem 3rem 1rem 2rem;
  background: #ffffff;
`;

export const FormSectionTitle = styled.p`
  font-size: 1.6rem;
  font-family: var(--font-primary);
  font-weight: 500;
  color: #1e293b;
  margin-bottom: 1.6rem;
`;

export const FormFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  &.logo-opportunity {
    flex-direction: row;
    column-gap: 1.6rem;
    > div:first-of-type {
      min-width: 6.4rem;
    }
    > :last-child {
      flex: 1;
    }
  }
  &.buyer-deal-team {
    flex-direction: row;
    justify-content: space-between;
    column-gap: 1.8rem;
  }
  .each-deal-team {
    width: 100%;
  }
`;
export const FormFieldWrapper = styled.div`
  &.opportunity-field {
    width: 100%;
    > div {
      height: 100%;
    }
    #opportunity {
      width: 100%;
    }
    .Select__menu {
      width: 100%;
    }
  }
`;

export const FilterDropDowns = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  flex-direction: column;
`;

export const SolutionAddEditFooter = styled.div`
  width: calc(100%-5rem);
  display: flex;
  justify-content: flex-end;
  column-gap: 1.6rem;
`;

export const CancelFormButton = styled(Button)`
  font-size: 1.3rem;
  background: #ffffff;
  color: #334155;
  font-family: var(--font-primary);
  font-weight: 500;
  border: 1px solid #e2e8f0;
  margin: 1.2rem 0;
  border-radius: 0.6rem;
`;

export const AccordionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background: #eff6ff;
  width: 100%;
  border: 1px solid #cbd5e1;
  padding: 0.8rem 1rem 0.8rem 1rem;
  border-radius: 0.8rem;
  margin: 0.3rem 0;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 200;
  user-select: none;
  svg {
    transform: rotate(-90deg);
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  &.open {
    svg {
      transform: rotate(0deg);
      transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }
`;

export const SaveFormButton = styled(CancelFormButton)`
  border: none;
  background: ${({ theme }: any) => theme.colors.button.background};
  color: #f8fafc;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    padding-right: 0.5rem;
  }
  :disabled {
    background-color: var(--border-gray);
  }
`;

export const DropdownLabel = styled.h2`
  font-size: 1.6rem;
  line-height: 2rem;
  color: #0f172a;
  font-family: var(--font-primary);
  text-overflow: clip;
  white-space: nowrap;
`;

export const DropdownHeaderContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;
`;

export const DropdownContainer = styled.div`
  width: 100%;
  .dropdown-selected-item {
    font-size: 1.3rem;
  }
`;

export const ErrorText = styled.p`
  color: var(--danger);
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-top: 0.5rem;
  ::first-letter {
    text-transform: capitalize;
  }
`;
