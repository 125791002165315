import styled from "@emotion/styled";

export const Button = styled.button`
  margin: 0 0.5rem;
  cursor: pointer;
  background: ${({ theme }: any) => theme.colors.button.background};
  color: #fff;
  text-decoration: none;
  border: 0;
  border-radius: 0.2rem;
  padding: 0.8rem 1.5rem;
  font-weight: 500;
  outline: none;
  font-family: var(--font-primary);
  letter-spacing: 0.6px;

  &.icon-button {
    display: flex;
    transition: all 200ms ease-in-out;
    .gap {
      margin-left: 1rem;
    }
  }
`;

export const Container = styled.div`
  padding: 1.6rem 2rem;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.04);
  border-radius: 1.2rem;
  background: var(--white);
  border: 0.5px solid #cbd5e1;
`;

export const ValidationErrorText = styled.p`
  color: #ef4444;
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 5px;
`;

export const InputGroup = styled.div`
  margin-bottom: 2.4rem;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const InputLabel = styled.h4`
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
  text-transform: uppercase;
  color: #5c5c5c;
`;

export const TableWrapper = styled.div`
  /* overflow: hidden; */
  width: 100%;
  border-radius: 1.2rem;
  background: #ffffff;

  &.hide-scrollbar {
    /* scrollbar-hidden::-webkit-scrollbar {
      display: none;
    } */
    ::-webkit-scrollbar {
      /* -webkit-appearance: none; */
      height: 0.5rem;
    }

    /* -ms-overflow-style: none; */
    scrollbar-width: 58rem; /* Firefox */
  }

  .flex {
    display: flex;
    align-items: flex-start;
  }

  .table-cell {
    padding: 2rem 2.8rem 1.6rem;
    font-size: 1.4rem;
  }

  &.table-wrapper {
    /* border: 1px solid #e1e1e1;
    width: 100%; */
    /* overflow-x: auto;
    overflow-y: auto;
    max-height: 74vh; */
    ::-webkit-scrollbar {
      /* -webkit-appearance: none; */
      height: 0.5rem;
      width: 0.5rem;
    }
  }

  table {
    background: #fff;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 1.2rem;
  }

  table thead th {
    border-bottom: 1.5px solid #e2e8f0;
    background: #f8fafc;
    position: sticky;
    top: 0;
    padding: 1.2rem 2.8rem;
    z-index: 2;
    /* text-transform: uppercase; */
  }
  table tbody td {
    border-bottom: 0.5px solid #e1e1e1;
  }
  /* table thead th:first-of-type,
  table tbody td:first-of-type {
    position: sticky;
    left: 0;
    z-index: 2;
    background: white;
    box-shadow: inset -7px 0 4px -7px rgba(0, 0, 0, 0.4);
  }

  table tbody td:first-of-type {
    background: linear-gradient(rgba(255, 255, 255, 1) 99%, #e1e1e1 1px);
  } */

  tbody tr:last-of-type {
    td {
      border-bottom: 0;
    }
  }

  td {
    vertical-align: top;
  }
  tbody tr:hover {
    background: #fafafa;
    cursor: pointer;
  }
  th {
    text-align: left;
  }

  th {
    color: #707070;
    font-size: 1.3rem;
  }

  td {
    font-size: 1.4rem;
    height: 4.7rem;
  }
`;

export const Card = styled.article`
  background: var(--card-background);
  width: 100%;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 1.2rem;
`;

export interface ITextPreviewBox {
  lineClamp?: number | "none";
}

export const TextPreviewBox = styled.div<ITextPreviewBox>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${(props) =>
    props.lineClamp === undefined ? 3 : props.lineClamp};
  overflow: ${(props) => (props.lineClamp === 0 ? "visible" : "hidden")};
  overflow-wrap: break-word;
`;
