import styled from "@emotion/styled";

interface IDropdownWrapper {
  width?: string;
  background?: string;
  borderColor?: string;
}
interface ILabelColor {
  color: string;
}
interface IDropdownSelectedItem {
  selectColor?: string;
}

export const DropdownWrapper = styled.div<IDropdownWrapper>`
  position: relative;
  background: ${({ background }) => background ?? "rgba(255, 255, 255, 0.14)"};
  width: calc(${({ width }) => width ?? "100%"});
  min-width: 23.6rem;
  border: 1px solid ${({ borderColor }) => borderColor ?? "#CBD5E1"};
  border-radius: 0.8rem;
`;

export const DropdownViewable = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.4rem;
  user-select: none;
  cursor: pointer;
  > div {
    width: 95%;
  }
  .selected-item-label {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .more {
    color: #2563eb;
    font-size: 1rem;
    margin-left: 1rem;
    white-space: nowrap;
    line-height: 150%;
  }
`;

export const DropdownLabel = styled.h3`
  font-size: 1.2rem;
  color: ${({ color }: ILabelColor) => color};
  font-family: var(--font-primary);
  margin-bottom: 0.6rem;
`;

export const DropdownSelectedItem = styled.h2<IDropdownSelectedItem>`
  min-height: 1.4rem;
  font-family: var(--font-primary);
  font-size: 1.4rem;
  color: ${({ selectColor }) => selectColor ?? "#A1A1AA"};
  line-height: 1.75rem;
`;

export const DropdownSearch = styled.input`
  display: inline-block;
  width: 100%;
  border: 0;
  box-sizing: border-box;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 150%;
  font-family: var(--font-primary);
  color: #64748b;
  outline: 0;
  ::placeholder {
    color: #cfcfcf;
  }
`;

interface IDropdownListWrapper {
  dropDownBgColor?: string;
  isOpen?: boolean;
}

export const DropdownListWrapper = styled.div<IDropdownListWrapper>`
  position: absolute;
  background: ${({ dropDownBgColor }) => dropDownBgColor ?? "var(--white)"};
  border-radius: 0.4rem;
  height: 29rem;
  z-index: 10;
  width: 22rem;
  left: 0;
  top: 100%;
  margin-top: 0.2rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  display: flex;
  border: 1px solid #dae1e9;
  box-shadow: 0px 16px 50px rgba(0, 0, 0, 0.08);
  padding: 1.2rem;
  flex-direction: column;
  .options-label {
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-bottom: 1.2rem;
    user-select: none;
  }
  .options {
    overflow-y: auto;
  }
  .options-search-box {
    display: flex;
    width: 100%;
    border: 1px solid #cbd5e1;
    border-radius: 0.6rem;
    padding: 0.8rem;
    margin-bottom: 1.2rem;
    gap: 0.8rem;
  }
`;

// DropdownList
export const DropdownList = styled.ul`
  font-size: 1.2rem;
  font-weight: 300;
`;

export const DropdownListItem = styled.li`
  cursor: pointer;
  &:hover {
    background: #f8faff;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.4;
    accent-color: #9c9a9a;
    color: #9c9a9a;
  }
`;

export const ListItemWrapper = styled.div`
  display: flex;
  padding: 0.6rem 0.8rem;
  align-items: center;
`;

export const ListItemCheckbox = styled.input`
  margin-right: 1rem;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 0;
`;

export const ListItemLabel = styled.div`
  color: #505050;
  width: 100%;
  user-select: none;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 150%;
`;
