import styled from "@emotion/styled";
import { Button } from "../../../../../styled";

export const CampaignFormContainer = styled.div`
  font-family: var(--font-primary);
  background: ${({ theme }: any) => theme.colors.body};
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4rem;
  .Header__title {
    color: white;
    font-size: 3rem;
  }
`;

export const CampaignFormBody = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  width: 57.4rem;
  border: 1px solid #cbd5e1;
  border-radius: 1.2rem;
  margin-bottom: 8.5rem;
  margin-top: 2rem;
  padding: 2rem 3rem 1rem 2rem;
  background: #ffffff;
`;

export const CampaignFormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 1.6rem;
`;

export const CancelFormButton = styled(Button)`
  font-size: 1.3rem;
  background: #ffffff;
  color: #334155;
  font-family: var(--font-primary);
  font-weight: 500;
  border: 1px solid #e2e8f0;
  margin: 1.2rem 0;
  border-radius: 0.6rem;
`;

export const SaveFormButton = styled(CancelFormButton)`
  border: none;
  background: ${({ theme }: any) => theme.colors.button.background};
  color: #f8fafc;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    padding-right: 0.5rem;
  }
  :disabled {
    background-color: var(--border-gray);
  }
`;

export const CSVIconContainer = styled.div`
  height: 6rem;
  width: 6rem;
  position: relative;
  border-radius: 1.2rem;
  background-color: #f1f5f9;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const CSVFileUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.6rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.6rem;
  padding: 1rem;
  input {
    visibility: hidden;
    display: none;
  }
`;

export const BrowseFileButton = styled.a`
  display: flex;
  align-items: center;
  width: max-content;
  padding: 0.5rem 0.6rem;
  background: #eff6ff;
  border-radius: 0.6rem;
  color: #3b82f6;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
  text-decoration: none;
`;

export const CRMAppContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.6rem;
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 0.6rem;
  display: flex;
  column-gap: 1.6rem;
  cursor: pointer;
`;

export const AppIconContainer = styled.div`
  height: 8rem;
  width: 8rem;
  border-radius: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AppsContainer = styled.div`
  display: flex;
  gap: 1rem;
`;
