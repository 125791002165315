import styled from "@emotion/styled";

export const DeleteContainer = styled.div``;
const WidgetButton = styled.div`
  color: #475569;
  height: max-content;
  font-size: 3.3rem;
  padding: 0.8rem 1rem;
  text-align: center;
  margin-top: 3.33rem;
  cursor: pointer;
  :hover {
    background-color: #eff6ff66;
  }
`;
export const DeleteIconContainer = styled(WidgetButton)`
  color: #dc2626;
`;
export const MessageWrapper = styled.div`
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  text-align: center;
  color: #312e81;
  margin: 1rem 3rem 1rem 3rem;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
`;
export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 15.5rem;
  height: 3.7rem;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.1rem;
`;
export const DeleteButton = styled(Button)`
  gap: 2rem;
  background: #b91c1c;
  border-radius: 5px;
  color: #f8fafc;
`;
export const CancelButton = styled(Button)`
  margin-left: 2rem;
  background: #ffffff;
  border: 0.5px solid rgba(148, 163, 184, 0.5);
  border-radius: 1.2rem;
  color: #0f172a;
`;
