import styled from "@emotion/styled";

export const VerticalCardsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 2rem 3rem 2rem 2rem;
  row-gap: 1rem;
  @media screen and (max-width: 960px) {
    padding: 2rem;
  }
`;

export const VerticalCardsHeader = styled.div`
  padding-top: 1rem;
  font-family: var(--font-primary);
  line-height: 3rem;
  font-size: 2rem;
  font-weight: 600;
  color: #1e293b;
  padding-bottom: 2rem;
`;

export const Card = styled.div`
  padding: 1.2rem 2.7rem 1.6rem 1.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--font-primary);
  /* background-color: #f9f9fb; */
  border-bottom: 1px solid #e2e8f0;
  /* border-radius: 1rem; */
  @media screen and (max-width: 960px) {
    padding: 1.2rem;
  }
`;

export const CardContentWrapper = styled.div`
  width: 100%;
  padding-right: 2rem;
  line-height: 2rem;
  font-weight: 300;
  @media screen and (max-width: 960px) {
    padding: 0;
  }
`;

export const Title = styled.div`
  color: black;
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 2.4rem;
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
`;

export const Description = styled.div`
  .b-embed {
    max-width: "97%";
  }
  /* color: #64748b; */
  color: black;
  font-size: 1.4rem;
  overflow-y: hidden;
  @media screen and (max-width: 960px) {
    .b-embed {
      max-width: 100%;
    }
    img {
      max-width: 100%;
    }
  }
  .tiny-pageembed--21by9,
  .tiny-pageembed--16by9,
  .tiny-pageembed--4by3,
  .tiny-pageembed--1by1 {
    display: block;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100%;
  }

  .tiny-pageembed--21by9 {
    padding-top: 42.857143%;
  }

  .tiny-pageembed--16by9 {
    padding-top: 56.25%;
  }

  .tiny-pageembed--4by3 {
    padding-top: 75%;
  }

  .tiny-pageembed--1by1 {
    padding-top: 100%;
  }

  .tiny-pageembed--21by9 iframe,
  .tiny-pageembed--16by9 iframe,
  .tiny-pageembed--4by3 iframe,
  .tiny-pageembed--1by1 iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

export const TagAndButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1.6rem;
`;

export const DocumentsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const CardFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  column-gap: 1rem;
`;
