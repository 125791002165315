import styled from "@emotion/styled";
import { Button } from "../../../../styled/common";

export const AddToSolutionPopupWidget = styled.div`
  padding: 0.6rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  color: #475569;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 2rem;
  cursor: pointer;
`;

export const RemoveFromProposalWidget = styled(AddToSolutionPopupWidget)`
  color: #dc2626;
`;

export const EditPopupWidget = styled(AddToSolutionPopupWidget)`
  color: #64748b;
`;

export const ViewInLibraryWidget = styled(AddToSolutionPopupWidget)`
  color: #64748b;
`;

export const CloseWidget = styled(AddToSolutionPopupWidget)`
  color: #64748b;
`;

export const SaveWidget = styled(Button)`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  padding: 0.6rem 1.2rem;
  color: #ffffff;
  background: ${({theme}: any) => theme.colors.button.background};
  border-radius: 0.6rem;
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 2rem;
  :disabled {
    background-color: var(--border-gray);
  }
`;

export const CancelWidget = styled(AddToSolutionPopupWidget)`
  padding: 0.6rem 1.2rem;
  color: #64748b;
  background: rgba(248, 250, 252, 0.5);
  border-radius: 0.6rem;
`;
