import * as Yup from "yup";
import validator from "validator";
import { FormFieldType } from "../constants";
import { IFieldStructure, IFormField } from "../interfaces";
import { getCSVParsedData } from "../components/compositional/Templates/components/Campaign/AddEditCampaignForm";
import { ISidebar2DataObj } from "../interfaces/solution";
import {
  ICampaignUserCard,
  IPriorInteractionSection,
} from "../interfaces/campaign";
import { noop } from "lodash";

interface ICampaignUserStatusBar {
  title: string;
  field_name: string;
}

interface ICampaignAddEditFormConfigObj {
  title?: string;
  editTitle?: string;
  formFields: IFormField[];
}

interface IEmailPromptAddEditConfigObj extends ICampaignAddEditFormConfigObj {}

export interface IFormFieldStructure {
  fields: string[];
}

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const campaignSearchItems = ["campaign", "campaign_user"];

export const campaignEditFormStructure: IFormFieldStructure[] = [
  {
    fields: ["emails", "csv"],
  },
];

export const campaignAddEditFormConfig: ICampaignAddEditFormConfigObj = {
  title: "Create new campaign",
  editTitle: "Edit Campaign",
  formFields: [
    {
      label: "Campaign name",
      type: FormFieldType.TEXT,
      name: "name",
      modelField: "name",
      validation: Yup.string().max(255).required("Campaign name is required"),
      fieldData: {
        initialValue: "",
        placeholder: "Enter campaign name",
      },
    },
    {
      label: "Emails/Domains",
      type: FormFieldType.TEXT_WITH_TAG,
      name: "emails",
      modelField: "users_data",
      fieldData: {
        initialValue: { tags: [], inputValue: "" },
        placeholder: "Enter comma separated email ids or domains",
      },
      validation: Yup.object()
        .test(
          "isEmailColumnExist",
          "Email or Domain column does not exists in this CSV file",
          async (value, context) => {
            const requiredError = context.createError({
              message: "Email or Domain is required",
            });
            const invalidEmailError = context.createError({
              message: "Invalid email id or domain",
            });
            const valueLength = (value?.tags as any[])?.length;
            if (!context.parent.csv && !value) return true;
            if (context.parent.csv) {
              const data: any = await getCSVParsedData(context.parent.csv);
              if (data.header) {
                if (!valueLength) {
                  if (value.inputValue) {
                    return (value.inputValue as String)
                      .split(",")
                      .every((email) =>
                        email
                          ? validator.isEmail(email) || validator.isFQDN(email)
                          : true
                      )
                      ? true
                      : invalidEmailError;
                  }
                  return requiredError;
                } else if (
                  data.header.includes("Email") ||
                  data.header.includes("email") ||
                  data.header.includes("Domain") ||
                  data.header.includes("domain")
                )
                  return true;
                else return false;
              }
            } else if (!valueLength) {
              if (value.inputValue) {
                return (value.inputValue as String)
                  .split(",")
                  .every((email) =>
                    email
                      ? validator.isEmail(email) || validator.isFQDN(email)
                      : true
                  )
                  ? true
                  : invalidEmailError;
              }
              return requiredError;
            }
            return true;
          }
        )
        .test(
          "isValidEmailIds",
          "Invalid email id",
          async (value, { createError, parent }) => {
            if (!parent.csv && !value) return true;
            const isValidEmailIds = ((value?.tags as any[]) || [])?.every(
              (emailData: any) =>
                validator.isEmail(emailData["Email"] || emailData["email"]) ||
                validator.isFQDN(emailData["Email"] || emailData["email"])
            );
            if (parent.csv) {
              const data: any = await getCSVParsedData(parent.csv);
              if (
                data.data &&
                (data.header.includes("Email") || data.header.includes("email"))
              ) {
                const invalidEmailRowsArr = data.data.reduce(
                  (invalidEmailRows: number[], csvData: any, index: number) => {
                    if (
                      !(
                        validator.isEmail(
                          (csvData["Email"] || csvData["email"] || "").trim()
                        ) ||
                        validator.isFQDN(
                          (csvData["Domain"] || csvData["domain"] || "").trim()
                        )
                      )
                    )
                      invalidEmailRows.push(index + 2);
                    return invalidEmailRows;
                  },
                  []
                );
                if (invalidEmailRowsArr.length && !isValidEmailIds) {
                  return createError({
                    message: `Invalid email id on row ${invalidEmailRowsArr.join(
                      ","
                    )}`,
                  });
                }
              }
            }
            return isValidEmailIds;
          }
        ),
    },
    {
      label: "Or upload a CSV file here",
      type: FormFieldType.CSV_FILE_UPLOAD,
      name: "csv",
      modelField: "csv",
      fieldData: {
        initialValue: "",
        browseFileButtonLabel: "Upload file",
      },
    },
  ],
};

export const emailPromptAddEditFormConfig: IEmailPromptAddEditConfigObj = {
  title: "",
  editTitle: "",
  formFields: [
    {
      label: "Choose template",
      type: FormFieldType.SELECT,
      name: "emailPrompt",
      modelField: "prompt",
      validation: Yup.object({
        label: Yup.string().max(100).required("Template is required"),
      })
        .required("Template is required")
        .nullable(),
      fieldData: {
        initialValue: "",
        placeholder: "Choose email prompt",
        isCreatable: false,
        options: [],
        selected: [],
        onChange: noop,
      },
    },
  ],
};

export const campaignUserSidebarConfig: ISidebar2DataObj[] = [
  {
    label: "Research",
    key: "research",
    visibility: "visible",
    onClick: () => {},
    subsections: [
      {
        label: "Qualification",
        key: "qualification",
        visibility: "visible",
        onClick: () => {},
      },
      {
        label: "Buyer data",
        key: "buyer_basic_details",
        visibility: "visible",
        onClick: () => {},
      },
      {
        label: "Company data",
        key: "company_basic_details",
        visibility: "visible",
        onClick: () => {},
      },
      {
        label: "TL;DR",
        key: "tldr",
        visibility: "visible",
        onClick: () => {},
      },
      {
        label: "CRM lead data",
        key: "buyer_crm_lead_data",
        visibility: "visible",
        onClick: () => {},
      },
      {
        label: "Prior interactions",
        key: "prior_interactions",
        visibility: "visible",
        onClick: () => {},
      },
      {
        label: "Key stakeholders",
        key: "key_stakeholders",
        visibility: "visible",
        onClick: () => {},
      },
      {
        label: "Size indicators",
        key: "size_indicators",
        visibility: "visible",
        onClick: () => {},
      },
      {
        label: "Marketing & Traffic data",
        key: "marketing_data",
        visibility: "visible",
        onClick: () => {},
      },
      {
        label: "Custom data",
        key: "company_custom_data",
        visibility: "visible",
        onClick: () => {},
      },
      {
        label: "Tech stack",
        key: "tech_stack",
        visibility: "visible",
        onClick: () => {},
      },
      // {
      //   label: "ADR",
      //   key: "adr_data",
      //   visibility: "visible",
      //   onClick: () => {},
      // },
      {
        label: "Know your customer deeply",
        key: "company_custom_questions",
        visibility: "visible",
        onClick: () => {},
      },
    ],
  },
  {
    label: "Value Narrative",
    key: "value_narrative",
    visibility: "visible",
    onClick: () => {},
    subsections: [
      {
        label: "Talking points",
        key: "talking_points",
        visibility: "visible",
        onClick: () => {},
      },
      {
        label: "Key challenges",
        key: "key_challenges",
        visibility: "hidden",
        onClick: () => {},
      },
      {
        label: "Personalized Email",
        key: "personalized_email",
        visibility: "visible",
        onClick: () => {},
      },
      {
        label: "Landing page",
        key: "personalized_landing_page",
        visibility: "visible",
        onClick: () => {},
      },
    ],
  },
];

export const campaignUserStatusBar: ICampaignUserStatusBar[] = [
  {
    title: "Pitch Generated",
    field_name: "shared_url",
  },
  {
    title: "Pitch Viewed",
    field_name: "solution.analytics.last_viewed_at",
  },
  {
    title: "Meeting Booked",
    field_name: "",
  },
  {
    title: "Sales Qualified",
    field_name: "",
  },
];

export const campaignContentConfig: ICampaignUserCard[] = [
  {
    name: "",
    key: "qualification",
    field_name: "",
    data: [],
  },
  {
    name: "Buyer data",
    key: "buyer_basic_details",
    data: [
      {
        label: "Email",
        field_name: "email",
      },
      {
        label: "Name",
        field_name: "extra_data.full_name",
        isStartCase: true,
      },
      {
        label: "About",
        field_name: "extra_data.summary",
      },
      {
        label: "Job title",
        field_name: "extra_data.job_title",
        isUpperFirst: true,
      },
      // {
      //   label: "ICP match",
      //   value: "Yes",
      // },
      {
        label: "Linkedin",
        field_name: "extra_data.linkedin_url",
        isLink: true,
      },
      // {
      //   label: "Phone",
      //   value: "",
      // },
      {
        label: "Location",
        field_name: "extra_data.location",
      },
      {
        label: "Phone number",
        key: "buyer_phone_number",
        field_name: "extra_data.phone_numbers",
      },
      // {
      //   label: "Team",
      //   value: "",
      // },
    ],
  },
  // {
  //   name: "Custom questions",
  //   key: "buyer_custom_questions",
  //   data: [
  //     {
  //       label: "",
  //       field_name: "extra_data.qa_list",
  //       isQA: true,
  //     },
  //   ],
  // },
  {
    name: "Company data",
    key: "company_basic_details",
    url_field_name: "extra_data.company.logo",
    data: [
      {
        label: "Name",
        field_name: "extra_data.company.name",
        isStartCase: true,
      },
      {
        label: "About",
        field_name: "extra_data.company.about",
        isUpperFirst: true,
      },
      {
        label: "Industry",
        field_name: "extra_data.company.industry",
        isUpperFirst: true,
      },
      {
        label: "Website",
        field_name: "extra_data.company.website",
        isLink: true,
      },
      {
        label: "Linkedin",
        field_name: "extra_data.company.linkedin_url",
        isLink: true,
      },
      {
        label: "Location",
        field_name: "extra_data.company.location_name",
      },
    ],
  },
  {
    name: "TL;DR",
    key: "tldr",
    field_name: "extra_data.insight_summary",
    data: [],
  },
  {
    name: "CRM lead data",
    key: "buyer_crm_lead_data",
    field_name: "extra_data.crm_data",
    data: [],
  },
  {
    name: "Prior interactions",
    key: "prior_interactions",
    data: [
      {
        label: "",
        field_name: "extra_data.company.prior_interactions",
        isPriorInteractions: true,
      },
    ],
  },
  {
    name: "Key stakeholders",
    key: "key_stakeholders",
    data: [
      {
        label: "",
        field_name: "extra_data.company.key_stakeholders",
        isPriorInteractions: true,
      },
    ],
  },
  {
    name: "Size indicators",
    key: "size_indicators",
    data: [
      {
        label: "",
        field_name: "extra_data.company.size_indicators",
        isSocialPresence: true,
      },
    ],
  },
  {
    name: "Marketing & Traffic data",
    key: "marketing_data",
    data: [
      {
        label: "",
        field_name: "extra_data.company.analytics",
        isMarketingData: true,
      },
    ],
  },
  {
    name: "Custom data",
    key: "company_custom_data",
    data: [
      {
        label: "",
        field_name: ["extra_data.company.custom_data"],
        isQA: true,
      },
    ],
  },
  {
    name: "Tech Stack",
    key: "tech_stack",
    data: [
      {
        label: "",
        field_name: "extra_data.company.tech_stack",
        isTechStack: true,
      },
    ],
  },
  // {
  //   name: "ADR Data",
  //   key: "adr_data",
  //   data: [
  //     {
  //       label: "",
  //       field_name: "extra_data.company.adr_data",
  //       isAdrData: true,
  //     },
  //   ],
  // },
  {
    name: "Know your customer deeply",
    key: "company_custom_questions",
    data: [
      {
        label: "",
        field_name: ["extra_data.qa_list", "extra_data.company.qa_list"],
        isQA: true,
      },
    ],
  },
  {
    name: "Talking points",
    key: "talking_points",
    data: [
      {
        label: "",
        field_name: "extra_data.talking_points",
      },
    ],
  },
  {
    name: "Key challenges",
    key: "key_challenges",
    hidden: true,
    data: [
      {
        label: "",
        field_name: "extra_data.key_challenges",
        isQA: true,
      },
    ],
  },
  {
    name: "Personalized Email",
    key: "personalized_email",
    data: [
      {
        label: "",
        field_name: "email_content",
        hasCopyButton: true,
      },
    ],
  },
  {
    name: "Landing Page",
    key: "personalized_landing_page",
    data: [
      {
        label: "Share link",
        field_name: "shared_url",
        isLink: true,
        hasCopyButton: true,
      },
      {
        label: "Edit link",
        field_name: "solution.id",
        isLink: true,
        hasCopyButton: true,
      },
    ],
  },
];

export const campaignFields: IFieldStructure[] = [
  // {
  //   field_name: "",
  //   label: "select",
  //   render_type: "checkbox",
  //   column_width: .2
  // },
  {
    field_name: "email_or_domain",
    label: "Email/Domain",
    render_type: "email_text",
    column_width: 1.4,
    enableSorting: true,
  },
  {
    field_name: "buyer_name",
    label: "Name",
    render_type: "text",
    column_width: 1,
    enableSorting: true,
  },
  {
    field_name: "status",
    label: "Research Status",
    render_type: "status",
    column_width: 1.4,
    enableSorting: true,
  },
  {
    field_name: "extra_data.company.name",
    label: "Company",
    render_type: "text",
    column_width: 1,
    enableSorting: true,
  },
  {
    field_name: "qualification_score",
    label: "Qualification Score",
    render_type: "progress_bar",
    column_width: 1,
    enableSorting: true,
  },
  {
    field_name: "priority",
    label: "Priority",
    render_type: "text",
    column_width: 0.01,
    enableSorting: true,
    isHidden: BASE_URL?.includes("web.vink.ai"),
  },
  {
    field_name: "extra_data.job_title",
    label: "Title",
    render_type: "text",
    column_width: 2,
    enableSorting: true,
  },
  {
    field_name: "solution.analytics.last_viewed_at",
    label: "Last viewed",
    render_type: "time_interval",
    column_width: 1,
    enableSorting: true,
  },
];

export const priorInteractionSection: IPriorInteractionSection[] = [
  {
    title: "Related leads",
    fields: [
      {
        label: "Name",
        field_name: "Name",
        render_type: "text",
        column_width: 1,
      },
      {
        label: "Email",
        field_name: "Email",
        render_type: "text",
        column_width: 1,
      },
      {
        label: "Status",
        field_name: "Status",
        render_type: "text",
        column_width: 1,
      },
      {
        label: "Owner",
        field_name: "owner",
        render_type: "text",
        column_width: 1,
      },
      {
        label: "Lead source",
        field_name: "LeadSource",
        render_type: "text",
        column_width: 1,
      },
      {
        label: "CRM link",
        field_name: "sf_url",
        render_type: "link",
        column_width: 0.6,
      },
      {
        label: "Company",
        field_name: "Company",
        render_type: "text",
        column_width: 1,
      },
    ],
  },
  {
    title: "Related opportunities",
    fields: [
      {
        label: "Name",
        render_type: "text",
        field_name: "name",
        column_width: 1,
      },
      {
        label: "Account Name",
        render_type: "text",
        field_name: "account",
        column_width: 1,
      },
      {
        label: "Stage",
        render_type: "text",
        field_name: "stage",
        column_width: 1,
      },
      {
        label: "Amount",
        render_type: "text",
        field_name: "amount",
        column_width: 1,
      },
      {
        label: "Close date",
        render_type: "text",
        field_name: "close_date",
        column_width: 1,
      },
      {
        label: "CRM link",
        render_type: "link",
        field_name: "sf_url",
        column_width: 1,
      },
    ],
  },
  {
    title: "Related accounts",
    fields: [
      {
        label: "Name",
        render_type: "text",
        field_name: "name",
        column_width: 1,
      },
      {
        label: "Website",
        render_type: "link",
        field_name: "website",
        column_width: 1,
      },
      {
        label: "Opportunity count",
        render_type: "text",
        field_name: "opportunities",
        column_width: 1,
      },
      {
        label: "CRM link",
        render_type: "link",
        field_name: "sf_url",
        column_width: 1,
      },
    ],
  },
];
