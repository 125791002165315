import styled from "@emotion/styled";
import fallbackIcon from "../../../../../../assets/icons/filetypes/txt.svg";
import {
  getFileNameFromUrl,
  getFileTypeIconFromUrl,
} from "../../../../../../helpers";
import Tooltip from "../../Tooltips";

interface IDocumentIconOther {
  marginRight: string;
}

const ViewDocumentButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--font-primary);
  border: 1px solid #cbd5e166;
  border-radius: 0.6rem;
  padding: 0.4rem 0.7rem;
  line-height: 2rem;
  font-size: 1.25rem;
  font-weight: 400;
  background: #ffffff;
  color: #475569;
  height: 3.2rem;
  &:hover {
    cursor: pointer;
    background: #f1f5f966;
    transition-delay: all 200ms ease-in-out;
  }
`;

export const DocumentIcon = styled.img`
  width: 2rem;
  height: 2rem;
  margin-right: 0.6rem;
  color: "#fefefe";
`;
export const DocumentIconOther = styled.img<IDocumentIconOther>`
  width: 2rem; 
  height: 2rem; 
  margin-right: ${(props) => props.marginRight};
  color: "#fefefe";
`;

interface IViewFileButton {
  filePath: string;
  onClick?: Function;
  buttonLabel?: string;
  toolTipText?: string;
  hideButtonLabel?: boolean;
  showToolTip?: boolean;
  thumbnailUrl?: string;
  object?: string;
}

const ViewFileButton = ({
  onClick,
  filePath = "",
  buttonLabel = "",
  toolTipText = "",
  hideButtonLabel = true,
  showToolTip = false,
  thumbnailUrl,
  object,
}: IViewFileButton) => {
  if (!filePath) return <></>;
  const iconUrl = getFileTypeIconFromUrl(filePath) || fallbackIcon;
  const fileNameToDisplay = getFileNameFromUrl(filePath, true);
  const fileNameExtension = fileNameToDisplay.split(".")[1];
  return (
    <>
      <ViewDocumentButton
        data-tip
        data-for={fileNameToDisplay}
        onClick={(e) => {
          e.stopPropagation();
          onClick && onClick(e);
        }}
      >
        {object == "battle_card" ? (
          fileNameExtension === "png" ? (
            <DocumentIcon src={thumbnailUrl} alt="Document icon" />
          ) : (
            <DocumentIcon src={iconUrl} alt="Document icon" />
          )
        ) : (
          <DocumentIconOther
            src={iconUrl}
            alt="Document icon"
            marginRight={showToolTip ? "0rem" : "0.6rem"}
          />
        )}
        {hideButtonLabel && (buttonLabel || fileNameToDisplay)}
        {showToolTip && (
          <Tooltip id={fileNameToDisplay}>
            {toolTipText || fileNameToDisplay}
          </Tooltip>
        )}
      </ViewDocumentButton>
    </>
  );
};

export default ViewFileButton;
