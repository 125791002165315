import styled from "@emotion/styled";

export const FilterDropdownListContainerWrapper = styled.div`
  margin: 0rem 3.2rem;
  padding: 1.6rem 2rem;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.04);
  border-radius: 1.2rem;
  background: var(--white);
  border: 0.5px solid #cbd5e1;
  .filter-title {
    font-size: 1.6rem;
    font-weight: 500;
    color: #312e81;
    font-family: var(--font-primary);
    margin-bottom: 1.2rem;
  }
  .clear {
    color: #dc2626;
    font-size: 1.4rem;
    line-height: 2.56rem;
    font-weight: 400;
    cursor: pointer;
    width: 9rem;
    img {
      display: inline-block;
      margin-right: 0.5rem;
      width: 2.4rem;
      height: 2.4rem;
    }
  }
  .apply-filter-btn {
    border-radius: 1.2rem;
  }
  .flex {
    flex-wrap: wrap;
    gap: 2rem;
    width: 100%;
  }
`;
