import styled from "@emotion/styled";

interface ISharedSolutionHeaderBody {
  backgroundColor: string;
}
interface ISolutionSharingContainer {
  isOpen?: boolean;
}

export const SharedSolutionContainer = styled.div`
  font-family: var(--font-primary);
`;

export const SharedSolutionBodyWrapper = styled.div`
  position: relative;
  @media screen and (max-width: 960px) {
    .tabbed__sidebar.client-view {
      display: none;
    }
    .sidebar__selected-tab-content.client-view {
      border: none;
      box-shadow: none;
      padding: 0;
      margin: 1rem;
      border-radius: 0;
    }
  }
`;

export const SharedSolutionBodyFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
  margin-right: 2rem;
  img {
    height: 2.5rem;
    cursor: pointer;
  }
  p {
    font-size: 1.3rem;
    font-weight: 350;
    color: #000000;
    user-select: none;
  }
`;

export const LeftSideFooter = styled.div`
  margin-left: 27rem;
  @media screen and (max-width: 960px) {
    margin-left: 1rem;
  }
`;

export const RightSideFooter = styled.div`
  display: flex;
  align-items: center;
`;

export const AppLink = styled.a`
  font-size: 1.3rem;
  font-weight: 350;
  color: rgb(37, 99, 235);
  cursor: pointer;
`;

export const SharedSolutionBodyContainer = styled.div`
  margin: 0 4.5rem;
  margin-top: -8rem;
  @media screen and (max-width: 960px) {
    margin: 0;
    top: 0;
  }
  #sidebar__tabs {
    border: none;
    background: none;
    box-shadow: none;
    margin-bottom: 0.5rem;
  }
  .tabbed__sidebar {
    background: #fdfdfd;
    box-shadow: 0 0.4rem 0.7rem rgba(0, 0, 0, 0.15);
    border-radius: 1.2rem;
    min-height: 90vh;
    margin: 1rem 0;
    margin-left: 10px;
  }
  .sidebar__selected-tab-content {
    background: none;
    border-radius: 1.2rem;
    min-height: 80rem;
    margin: 1rem;
    margin-left: 1rem;
    margin-bottom: 0;
    padding: 0;
  }
  .each-tab-content {
    margin-bottom: 1.5rem;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    box-shadow: rgb(0 0 0 / 10%) 0px 0.2rem 0.4rem;
    border-radius: 1.2rem;
    padding: 0 1.8rem;
    min-height: 25rem;
  }
`;

export const SharedSolutionHeaderContainer = styled.div``;

export const SharedSolutionHeaderBody = styled.div<ISharedSolutionHeaderBody>`
  height: 31rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 8.8rem;
  padding-right: 8.8rem;
  background: ${({ backgroundColor }) => backgroundColor};
  column-gap: 10rem;
  @media screen and (max-width: 960px) {
    height: fit-content;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 6rem;
    padding: 2rem;
    column-gap: 0;
    row-gap: 2rem;
  }
`;

export const SolutionTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 2.7rem;
  line-height: 3.9rem;
  color: #ffffff;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
  @media screen and (max-width: 960px) {
    font-size: 2rem;
    line-height: 3rem;
  }
`;

export const SolutionLogoContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.25);
  border-radius: 0.7rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  height: 8rem;
  img {
    height: 100%;
    border-radius: 0.5rem;
  }
  @media screen and (max-width: 960px) {
    padding: 0.5rem;
    height: 5rem;
  }
`;

export const SharedSolutionNavBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 3.3rem;
  padding-right: 2.8rem;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  z-index: 100;
  img {
    height: 3.7rem;
  }
  @media screen and (max-width: 960px) {
    padding-top: 0;
    padding-bottom: 0;
    height: 6rem;
    img {
      max-height: 60px;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 0.7rem;
`;

export const ShareButtonWrapper = styled.div`
  position: relative;
`;
export const ShareButton = styled.div`
  background: ${({ theme }: any) => theme.colors.button.background};
  border-radius: 0.6rem;
  padding: 0.6rem 1.6rem;
  align-items: center;
  display: flex;
  cursor: pointer;
  span {
    font-family: var(--font-primary);
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 2rem;
    text-align: center;
    color: #f8fafc;
  }
`;
export const EditButton = styled(ShareButton)`
  background: #ffffff;
  border-radius: 0.8rem;
  border: 1px solid #cbd5e1;
  color: #334155;
  gap: 0.5rem;
  span {
    color: #334155;
  }
`;

export const CopyButton = styled.button`
  background: #eff6ff;
  border: 1px solid #e2e8f0;
  border-radius: 0.6rem;
  font-family: var(--font-primary);
  font-size: 1.2rem;
  font-weight: 600;
  color: #2563eb;
  text-align: center;
  padding: 0.6rem 1.2rem;
  line-height: 1.8rem;
  cursor: pointer;
`;

export const LinkcopiedMessage = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: var(--font-primary);
  font-size: 1rem;
  font-weight: 400;
  color: #16a34a;
  padding-top: 0.2rem;
  span {
    margin-left: 0.1rem;
  }
`;

export const SolutionSharingBody = styled.div`
  padding: 2rem;
`;

export const SolutionSharingContainer = styled.div<ISolutionSharingContainer>`
  position: absolute;
  top: 100%;
  right: 0;
  width: 34.9rem;
  border: 1px solid #cbd5e1;
  border-radius: 0.9rem;
  background-color: #ffffff;
  box-shadow: 0rem 1.2rem 4rem rgba(0, 0, 0, 0.2);
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  margin-top: 0.8rem;
  z-index: 10;
  @media screen and (max-width: 960px) {
    &.client-view {
      width: 34.5rem;
    }
  }
`;

export const SharingLinkContainer = styled.div`
  width: 30.9rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e2e8f0;
  background: #f8fafc;
  border-radius: 0.8rem;
  padding: 0.6rem;
  padding-left: 1.2rem;
  p {
    max-width: 20.4rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: var(--font-primary);
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4rem;
  }
  @media screen and (max-width: 960px) {
    &.client-view {
      width: 30.5rem;
      p {
        max-width: 20rem;
      }
    }
  }
`;

export const SidebarWithTabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--white);
  font-size: 1.4rem;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.04);
  border: 0.5px solid #e2e8f0;
  border-radius: 1.2rem;
  overflow: hidden;
  margin-bottom: 1.5rem;
  min-height: 80rem;
`;

export const SidebarWithTabsBody = styled.div`
  display: flex;
`;

export const SelectedTabContent = styled.div`
  width: 100%;
  overflow-y: auto;
`;
