import styled from "@emotion/styled";

export const EmbedHeader = styled.div`
  padding: 1rem 1.6rem;
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const EmbedHeaderTitle = styled.div`
  font-family: var(--font-primary);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.3;
`;

export const EmbedCloseButton = styled.div`
  cursor: pointer;
  .close-icon {
    color: #858585;
    transition: color, transform 100ms ease-in-out;
    font-size: 1.8rem;
    &:hover {
      color: #505050;
      transform: scale(1.2);
    }
  }
`;

export const EmbedBody = styled.div`
  padding: 0.8rem 1.6rem;
`;

export const EmbedUrlLabel = styled.label`
  font-family: var(--font-primary);
  color: rgba(34, 47, 62, 0.7);
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  padding: 0 8px 0 0;
  text-transform: none;
  white-space: nowrap;
`;

export const EmbedUrlSubText = styled.p`
  width: 100%;
  font-family: var(--font-primary);
  color: rgba(34, 47, 62, 0.7);
  display: block;
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 1.3;
  padding: 0.8rem 0.8rem 0 0;
`;

export const EmbedUrlInput = styled.input`
  font-family: var(--font-primary);
  margin-top: 0.6rem;
  width: 100%;
  padding: 1.2rem 1.6rem;
  background: #ffffff;
  border: 1px solid #e7e5e4;
  border-radius: 0.6rem;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #0f172a;
  box-shadow: none;
  &:focus {
    border: 1px solid #2563eb;
    box-shadow: 0 0 0 0.4rem rgba(37, 99, 235, 0.1);
    outline: none;
  }
  &::placeholder {
    color: #a1a1aa;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 2rem;
  }
`;

export const ButtonWrapper = styled.div`
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const CancelButton = styled.div`
  font-family: var(--font-primary);
  margin-left: 0.8rem;
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
  border-radius: 0.6rem;
  color: #222f57;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: normal;
  padding: 0.8rem 1.6rem;
  cursor: pointer;
  &:hover {
    background-color: #e3e3e3;
  }
`;

export const SaveButton = styled(CancelButton)`
  width: 7rem;
  background-color: #006ce7;
  color: #ffffff;
  text-align: center;
  &:hover {
    background-color: #0061d0;
  }
`;
