import styled from "@emotion/styled";
import { Button as BaseButton } from "../../../styled/common";

export const ErrorWrapper = styled.div`
  padding: 2rem;
  margin-top: 25rem;
  font-family: var(--font-primary);
  color: #444444;
  font-weight: 400;
`;

export const ErrorContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Text = styled.div`
  margin-bottom: 0.5rem;
  font-size: 1.4rem;
`;

export const Button = styled(BaseButton)`
  margin: 1rem 0.5rem;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
`;
