import styled from "@emotion/styled";
import { FormFieldWrapper } from "../styled";

export const RelParamTagCardWrapper = styled.div`
  height: 27rem;
  width: 21.3rem;
  background: var(--white);
  border: 0.1rem solid #cbd5e1;
`;

export const RelParamsFieldWrapper = styled(FormFieldWrapper)`
  .extra-text {
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 150%;
    color: #a1a1aa99;
  }
  .placeholder-text {
    .Tag__content {
      font-weight: 400;
      font-size: 1.3rem;
      color: #8896a9;
      line-height: 1.8rem;
      background: none;
      padding: 0;
      cursor: default;
      .Tag__label::after {
        content: "";
      }
    }
  }
  :focus-within {
    border: 0.1rem solid #2563eb;
    box-shadow: 0 0 0 0.4rem rgba(37, 99, 235, 0.1);
    border-radius: 1.2rem;
  }
`;

export const RelParamTagCardsContainer = styled.div`
  display: flex;
  gap: 3rem;
  row-gap: 2rem;
  flex-wrap: wrap;
  margin-top: 1rem;
`;

export const ToggleButton = styled.div`
  min-width: 3.5rem;
  width: 3.5rem;
  height: 2rem;
  position: relative;
  display: flex;
  input {
    display: none;
  }
  .slider {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(90deg, #b8babf 0%, #e5e7eb 100%);
    border-radius: 5rem;
    display: flex;
    transition: 0.4s;
    cursor: pointer;
  }
  .slider:before {
    content: "";
    position: absolute;
    top: 0.2rem;
    left: 0.2rem;
    width: 1.65rem;
    height: 1.65rem;
    background: #ffffff;
    border-radius: 1rem;
    box-shadow: 0rem 0rem 0.4rem rgba(0, 0, 0, 0.25);
    transition: 0.4s;
  }
  input:checked + .slider:before {
    transform: translateX(1.5rem);
  }
  input:checked + .slider {
    background: linear-gradient(90deg, #3b82f6 0%, #1d4ed8 100%);
  }
`;

export const LabelWithToggle = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;
  .toggle-label {
    margin: 0.8rem 0;
  }
`;
